import React from "react"

import { StaticQuery, graphql } from "gatsby"


const Images = ({details}) => {
  return <StaticQuery
    query={graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
            relativeDirectory: { regex: "/^work/" }
          }
        ) {
          edges {
            node {
              base
              relativePath
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let sorted_images = data.allFile.edges
        .filter(({ node }) => {
          return (
            ("/" + node.relativePath).indexOf(details.path) > -1 &&
            /[0-9]{3}.[a-z]{3,4}$/g.test(node.relativePath)
          )
        })
        .sort((a, b) =>
          a.node.relativePath.localeCompare(b.node.relativePath)
        )
      return (
        <div className="imagery">
          <img
            src={sorted_images[0].node.childImageSharp.fluid.src}
            className="hero"
            alt="" 
          />
          <div className="details">
            {sorted_images.slice(1).map(({ node },i) => {
              return <img key={i} src={node.childImageSharp.fluid.src} alt="" />
            })}
          </div>
        </div>
      )
    }}
  />
}

export default Images