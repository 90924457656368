/* eslint jsx-a11y/media-has-caption: 0 */
import React from "react"

import { StaticQuery, graphql } from "gatsby"



const Videos = ({details})=>{
  return <StaticQuery
      query={graphql`
        query{
          allFile(filter: {extension: {regex: "/mp4/"}, relativeDirectory: {regex: "/^work/"}}) {
            edges {
              node {
                base
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={data => {
        // return <p>{JSON.stringify(data.allFile)}</p>
        let sorted_videos = data.allFile.edges
          .filter(({ node }) => {
            return (
              ("/" + node.relativePath).indexOf(details.path) > -1 &&
              /[0-9]{3}.[a-z0-9]{3,4}$/g.test(node.relativePath)
            )
          })
          .sort((a, b) =>
            a.node.relativePath.localeCompare(b.node.relativePath)
          )
        if (sorted_videos.length === 0) {
          return ""
        }
        return (
          <div className="videos">
            {sorted_videos.map(({ node },i) => {
              return (
                <video controls preload key={i}>
                  <source src={node.publicURL} type="video/mp4" />
                </video>
              )
            })}
          </div>
        )
      }}
    />
}

export default Videos