import React from "react"

import Layout from "./layout"
import SEO from "./seo"
import Images from './work-images'
import Videos from './work-videos'
// import { StaticQuery, graphql } from "gatsby"




const InvolvedParties = ({ parties }) => {
  if (parties && parties.length) {
    return (
      <p>
        <strong>Involved Parties:</strong>
        <br />
        {parties.map(({ role, name }, i) => {
          return (
            <span key={i}>
              {role} - <strong>{name}</strong>
              <br />
            </span>
          )
        })}
      </p>
    )
  } else {
    return ""
  }
}

const Links = ({links}) =>{
  if (links && links.length) {
    return (
      <p>
        <strong>Links:</strong>
        <br />
        {links.map((link, i) => {
          return (<><a href={link} target="_blank">{link}</a><br/></>)
        })}
      </p>
    )
  } else {
    return ""
  }
}

const WorkDetailPage = ({ pageContext: { details } }) => (
  <Layout>
    <SEO title={details.title} />
    <h1 className="work-title">{details.title}</h1>
    <Images details={details} />
    <Videos details={details} />
    {details.description ? details.description.map((d,i) => <p key={i}>{d}</p>) : ""}
    {details.technologies ? (
      <p>
        <strong>Technologies Involved:</strong>{" "}
        {details.technologies.join(", ")}
      </p>
    ) : (
      ""
    )}
    {/* {details.links  ? details.links.map((v) => { return (<span>{v}</span>)}) : typeof(details.links)} */}
    <Links links={details.links} />
    <InvolvedParties parties={details.parties} />
    <p>
      Developed while employed at <strong>{details.company}</strong>
    </p>
  </Layout>
)

export default WorkDetailPage
